import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export const EmailModel = ({ setShowEmailModel, selectedLeads }) => {
  const [selectedEmail, setSelectedEmail] = useState("");
  const [customEmail, setCustomEmail] = useState("");
  const [customPassword, setCustomPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [senderName, setSenderName] = useState("");
  const [replyToEmail, setReplyToEmail] = useState("");

  const handleEmailChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSendEmail = async () => {
    if (!selectedEmail) {
      return alert("Select a method to send email");
    }
    const token = localStorage.getItem("push-notification-token");
    const recipientEmails =
      selectedLeads.length > 0 ? selectedLeads.map((lead) => lead.email) : []; // Use single recipient if sending individual email
    const recipientNames =
      selectedLeads.length > 0 ? selectedLeads.map((lead) => lead.name) : [];
    if (recipientEmails.length === 0) {
      alert("No leads selected.");
      return;
    }
    let emailPayload;
    let apiUrl;
    const mobile = localStorage.getItem("mobile");
    const whatsapp = localStorage.getItem("whatsap");
    const email = localStorage.getItem("email");
    const name = localStorage.getItem("name");
    const formatBody = emailBody
      .replace("{{whatsapp}}", whatsapp)
      .replace("{{email}}", email)
      .replace("{{euser}}", name)
      .replace("{{mobile}}", mobile);
    if (selectedEmail === "admin") {
      emailPayload = {
        recipientEmail: recipientEmails,
        subject: emailSubject,
        body: formatBody,
        fromOfficialAccount: "true",
        senderName: senderName,
        recipientnames: recipientNames,
        replyToEmail: replyToEmail,
        token: token,
      };
      apiUrl = "https://margda.in:7000/api/sendemail";
    } else if (selectedEmail === "custom") {
      if (!customEmail || !customPassword) {
        return alert("Provide custom email and password");
      }
      emailPayload = {
        recipientEmail: recipientEmails,
        subject: emailSubject,
        body: formatBody,
        senderEmail: customEmail,
        senderPassword: customPassword,
        senderName: senderName,
        recipientnames: recipientNames,
        replyToEmail: replyToEmail,
        token: token,
      };
      apiUrl = "https://margda.in:7000/api/sendemail";
    } else if (selectedEmail === "aws") {
      emailPayload = {
        recipientEmails: recipientEmails,
        recipientnames: recipientNames,
        subject: emailSubject,
        body: formatBody,
        senderName: senderName,
        replyToEmail: replyToEmail,
        token: token,
      };
      apiUrl = "https://margda.in:7000/api/aws/sendemail";
    } else if (selectedEmail === "outlook") {
      emailPayload = {
        recipientemails: recipientEmails,
        subject: emailSubject,
        body: formatBody,
        recipientnames: recipientNames,
        senderName: senderName,
        replyTo: replyToEmail,
        token: token,
      };
      apiUrl = "https://margda.in:7000/api/margda/sendemail/outlook";
    } else if (selectedEmail === "smtp_outlook") {
      if (!customEmail || !customPassword) {
        return alert("Provide custom email and password");
      }
      emailPayload = {
        recipientEmails: recipientEmails,
        subject: emailSubject,
        body: formatBody,
        recipientnames: recipientNames,
        senderEmail: customEmail,
        senderPassword: customPassword,
        senderName: senderName,
        replyToEmail: replyToEmail,
        token: token,
      };
      apiUrl = "https://margda.in:7000/api/margda/sendemail/smtp_outlook";
    }
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data);
        const message = data.message.responses;
        if (selectedEmail === "aws") {
          alert("Email Send Successfully");
        } else {
          alert(message);
        }
        setEmailSubject("");
        setEmailBody("");
      } else {
        alert("Failed to send email." + data.message);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div
        className="bg-white p-6 rounded-lg shadow-lg"
        style={{
          "overflow-x": "scroll",
          "max-height": "517px",
          width: "800px",
        }}
      >
        <h2 className="text-lg font-semibold mb-4">Send Email</h2>

        {/* Email Selection Dropdown */}
        <select
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
          value={selectedEmail}
          onChange={handleEmailChange}
        >
          <option value="">Select Email</option>
          <option value="admin">admin@gmail.com</option>
          <option value="custom">Custom Gmail With App password</option>
          <option value="aws">AWS</option>
          <option value="outlook">Outlook</option>
          <option value="smtp_outlook">SMTP Outlook</option>
        </select>

        {/* Show custom email/password inputs when "Custom Emails" is selected */}
        {(selectedEmail === "custom" || selectedEmail === "smtp_outlook") && (
          <>
            <input
              type="email"
              placeholder={
                selectedEmail === "custom"
                  ? "Enter Gmail"
                  : "Enter outlook email"
              }
              value={customEmail}
              onChange={(e) => setCustomEmail(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
            />

            {/* Password Input with Toggle */}
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                placeholder={
                  selectedEmail === "custom"
                    ? "Enter Gmail SMTP Password"
                    : "Enter Outlook Smtp Password"
                }
                value={customPassword}
                onChange={(e) => setCustomPassword(e.target.value)}
                className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-3 flex items-center"
              >
                {showPassword ? (
                  <FaEyeSlash className="text-gray-600 mb-5" />
                ) : (
                  <FaEye className="text-gray-600 mb-5" />
                )}
              </button>
            </div>
          </>
        )}

        {/* Subject and Body */}
        <input
          type="text"
          placeholder="Enter Sender Name"
          value={senderName}
          onChange={(e) => setSenderName(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
        />
        <input
          type="text"
          placeholder="Enter reply_to Email"
          value={replyToEmail}
          onChange={(e) => setReplyToEmail(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
        />
        <input
          type="text"
          placeholder="Email Subject"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
        />
        <textarea
          placeholder="Enter Html Message Here"
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
          rows="6"
        />

        <div
          id="preview"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            marginTop: "10px",
            overflowX: "scroll",
          }}
          className="w-full p-2 mb-4 border-gray-300"
          dangerouslySetInnerHTML={{
            __html: emailBody ? emailBody : "Preview Will be Show Here",
          }}
        />

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setShowEmailModel(false)}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSendEmail}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
};
