import { useEffect, useState } from "react";
import Papa from "papaparse";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { EmailModel } from "./EmailModel";
import { AddLeadForm } from "./AddLeadForm";
import { WhatsAppCon } from "../Components/WhatsAppCon";
import Loader from "../Components/Loader";

export const AllLeads = () => {
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [currentData, setCurrentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [showAddLead, setShowAddLead] = useState(false);
  const [showWhatsAppSend, setShowWhatsAppSend] = useState(false);
  const [showEmailModel, setShowEmailModel] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFilteredFrom, setShowFilteredFrom] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);

  // Expected columns for validation
  const expectedHeaders = ["name", "email", "mobile"];

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    const userId = localStorage.getItem("userID");
    if (!userId) return setLeads([]);

    try {
      const response = await fetch("https://margda.in:7000/api/getleads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID: userId }),
      });

      if (!response.ok) throw new Error("Failed to fetch leads");
      const data = await response.json();
      console.log("API response:", data);
      setLeads(data.Leads);
      setTotalPages(Math.ceil(data.Leads.length / entriesToShow));
      setCurrentData(data.Leads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    const data = filteredLeads.slice(
      (page - 1) * entriesToShow,
      page * entriesToShow
    );

    setCurrentData(data);
    setTotalPages(Math.ceil(filteredLeads.length / entriesToShow));
  }, [entriesToShow, page, searchTerm]);

  const downloadSample = () => {
    const data =
      "name,email,mobile\nJohn Doe,john@example.com,1234567890\nJane Doe,jane@example.com,1234567890"; // Example data (e.g., CSV content)
    const blob = new Blob([data], { type: "text/csv" }); // Create a Blob object with the file content
    const url = URL.createObjectURL(blob); // Create a URL for the Blob

    const a = document.createElement("a"); // Create an anchor element
    a.href = url; // Set the href to the Blob URL
    a.download = "contacts.csv"; // Set the download filename
    a.click(); // Programmatically click the link to start the download

    URL.revokeObjectURL(url); // Clean up the Blob URL
  };

  const handleCheckboxChange = (index) => {
    const selectedLead = leads[index];
    setSelectedLeads((prevSelectedLeads) =>
      prevSelectedLeads.includes(selectedLead)
        ? prevSelectedLeads.filter((lead) => lead !== selectedLead)
        : [...prevSelectedLeads, selectedLead]
    );
  };

  const handleSelectAll = (isChecked) => {
    setSelectedLeads(isChecked ? [...leads] : []);
  };

  const handleShowWhatsApp = () => {
    if (selectedLeads.length === 0) {
      return alert("Select a lead to send message");
    }
    setShowWhatsAppSend(true);
  };

  const handleShowEmail = () => {
    if (selectedLeads.length === 0) {
      return alert("Select a lead to send email");
    }
    setShowEmailModel(true);
  };

  const handleAddLead = () => setShowAddLead(true);

  const filteredLeads = leads.filter(
    (lead) =>
      (lead.name &&
        lead.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lead.email &&
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (lead.mobile && lead.mobile.includes(searchTerm))
  );

  const handleDeleteLead = async (leadID) => {
    const userID = JSON.parse(localStorage.getItem("userID"));
    console.log("Attempting to delete lead with ID:", leadID); // Log the lead ID

    if (!window.confirm("Are you sure you want to delete this lead?")) {
      console.log("Delete action canceled by the user.");
      return;
    }

    try {
      const response = await fetch(`https://margda.in:7000/api/deletelead`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, leadID }),
      });

      if (!response.ok) {
        console.error(
          "Failed to delete lead. Server responded with:",
          response.status
        );
        throw new Error("Failed to delete lead");
      }

      const result = await response.json();

      // Remove the deleted lead from the state
      setLeads((prevLeads) =>
        prevLeads.filter((lead) => lead.leadID !== leadID)
      );

      console.log("Lead successfully deleted from state.");
    } catch (error) {
      console.error("Error occurred while deleting lead:", error.message);
      alert("Failed to delete lead. Please try again.");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true, // Treat the first row as headers
        skipEmptyLines: true, // Skip empty lines
        complete: (results) => {
          const { data, meta } = results;
          const fileHeaders = meta.fields;
          const lowerCaseFileHeaders = fileHeaders.map((header) =>
            header.toLowerCase()
          );
          const lowerCaseExpectedHeaders = expectedHeaders.map((header) =>
            header.toLowerCase()
          );

          // Validate headers
          if (
            JSON.stringify(lowerCaseFileHeaders) !==
            JSON.stringify(lowerCaseExpectedHeaders)
          ) {
            // setErrorMessage(
            //   `Invalid columns. Expected: ${expectedHeaders.join(", ")}.`
            // );
            alert(`Invalid columns. Expected: ${expectedHeaders.join(", ")}`);
            setTableData([]);
            setHeaders([]);
            return;
          }

          // If valid, set headers and table data
          setErrorMessage("");
          setHeaders(fileHeaders);
          setTableData(data);
          setShowTable(true);
        },
      });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const saveLead = async (lead) => {
    setLoading(true);
    const userId = localStorage.getItem("userID");
    lead.userID = userId;
    console.log(lead);
    try {
      const response = await fetch("https://margda.in:7000/api/addlead", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lead),
      });

      const result = await response.json();
      if (response.ok) {
        // window.location.reload();
        console.log("lead added");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateEmail = async (index) => {
    setLoading(true);
    const email = currentData[index].email;
    const response = await fetch(
      "https://margda.in:7000/api/email/validateemail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, validate_type: "email" }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      if (data.email) {
        currentData[index].exists = true;
      } else {
        currentData[index].notExists = true;
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
    // console.log(response);
  };

  useEffect(() => {
    setPage(1);
    if (searchTerm !== "") {
      setShowFilteredFrom(true);
    } else {
      setShowFilteredFrom(false);
    }
  }, [searchTerm]);

  const handleAddLeadFromCsv = async () => {
    setShowTable(false);
    const promises = tableData.map((entry, index) => {
      const lead = {
        name: entry.name,
        email: entry.email,
        mobile: entry.mobile,
      };
      setTimeout(() => {
        saveLead(lead);
      }, index * 500);
    });
    await new Promise(promises);
    window.location.reload();
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col flex-wrap justify-between items-center mb-6">
        <div className="flex justify-between items-center my-5 w-[100%]">
          <div>
            <label htmlFor="entries" className="mr-2 text-gray-700 text-sm">
              Show Entries:
            </label>
            <select
              id="entries"
              value={entriesToShow}
              onChange={(e) => setEntriesToShow(Number(e.target.value))}
              className="p-[2px]  border text-[20px] font-normal  border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-300"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="items-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-1 border border-slate-900 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-slate-900 font-light text-xl"
            />
          </div>
        </div>
        {loading ? <Loader /> : <></>}
        {tableData.length > 0 && showTable ? (
          <div class="fixed inset-0 flex items-center justify-center z-50">
            <div class=" border border-gray-500 relative bg-white shadow-lg rounded-lg overflow-x-auto overflow-y-auto max-h-[700px] w-3/4 p-6">
              <div style={{ textAlign: "end" }}>
                <button
                  onClick={handleAddLeadFromCsv}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 mb-2"
                >
                  Add all leads
                </button>
              </div>{" "}
              <table className="border-collapse border border-gray-900 w-full">
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={index}
                        className="border border-gray-300 p-2 bg-gray-200"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {headers.map((header, colIndex) => (
                        <td
                          key={colIndex}
                          className="border border-gray-300 p-2 text-center"
                        >
                          {row[header]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div>
          <input
            type="file"
            id="uploadCsv"
            accept=".csv"
            onChange={handleFileUpload}
            className="hidden"
          />
        </div>
      </div>

      <div className="flex justify-start space-x-4 mb-4">
        <button
          onClick={handleShowEmail}
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
        >
          Send Email
        </button>
        <button
          onClick={handleShowWhatsApp}
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
        >
          Send WhatsApp
        </button>
        <button
          onClick={handleAddLead}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Add New Lead
        </button>
        {/* <div> */}
        <label
          htmlFor="uploadCsv"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 cursor-pointer"
        >
          Upload CSV
        </label>
        {/* {errorMessage && (
            <p className="text-red-500" style={{ fontSize: "16px" }}>
              {errorMessage}
            </p>
          )} */}
        {/* </div> */}
        <button
          onClick={downloadSample}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Sample Csv
        </button>
      </div>

      {currentData.length === 0 ? (
        <div className="text-center text-gray-600">No leads found</div>
      ) : (
        <div className="rounded-lg shadow-lg p-4">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-800 text-white text-center">
                <th className="py-3 px-4">
                  <input
                    type="checkbox"
                    checked={selectedLeads.length === leads.length}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                  />
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Name
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Mobile
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Email
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Validate Email
                </th>
                <th className="py-3 px-4 text-justify uppercase font-semibold text-sm">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.slice(0, entriesToShow).map((lead, index) => (
                <tr
                  key={index}
                  className="border-t hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => {
                    // Prevent toggle when clicking directly on the checkbox
                    if (e.target.type !== "checkbox") {
                      handleCheckboxChange(index);
                    }
                  }}
                >
                  <td className="py-3 px-4">
                    <input
                      type="checkbox"
                      checked={selectedLeads.includes(lead)}
                      className="cursor-pointer"
                      onChange={() => handleCheckboxChange(index)}
                      onClick={(e) => e.stopPropagation()} // Prevent row click
                    />
                  </td>

                  <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                    {lead.name}
                  </td>
                  <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                    {lead.mobile}
                  </td>
                  <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                    {lead.email}
                  </td>
                  {lead.exists ? (
                    <td>
                      <div
                        className="bg-green-500 text-white px-4 py-2 rounded-lg"
                        style={{ fontSize: "17px" }}
                      >
                        email exists
                      </div>
                    </td>
                  ) : lead.notExists ? (
                    <td>
                      <div
                        className="bg-red-500 text-white px-4 py-2 rounded-lg"
                        style={{ fontSize: "15px" }}
                      >
                        email doesn't exists
                      </div>
                    </td>
                  ) : (
                    <td className="py-[9px] px-4 text-justify text-base font-sans font-normal min-w-50">
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          validateEmail(index);
                        }}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                      >
                        validate
                      </button>
                    </td>
                  )}

                  <td className="py-[9px] px-4 flex justify-around">
                    <FaEdit
                      className="cursor-pointer text-blue-500"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        // Add edit functionality here
                      }}
                    />
                    <button
                      className="cursor-pointer text-blue-500"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleDeleteLead(lead.leadID);
                      }}
                    >
                      <FaTrash />
                    </button>
                    <FaEye
                      className="cursor-pointer text-blue-500"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        // Add view functionality here
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row justify-between mt-3">
            <div className="flex flex-row">
              {page === totalPages ? (
                <div className="text-base font-light ">
                  Showing {(page - 1) * entriesToShow + 1} to{" "}
                  {filteredLeads.length} of {filteredLeads.length} entries{" "}
                </div>
              ) : (
                <div className="text-base font-light ">
                  Showing {(page - 1) * entriesToShow + 1} to{" "}
                  {page * entriesToShow} of {filteredLeads.length} entries{" "}
                </div>
              )}
              {
                <div className="font-light text-base ml-2">
                  {showFilteredFrom &&
                    `(filtered from ${leads.length} entries)`}
                </div>
              }
            </div>
            <div className="flex justify-center space-x-2">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
                className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
              >
                Previous
              </button>

              {/* Page Numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-3 py-2 font-normal text-sm rounded hover:bg-slate-500 hover:text-white ${
                    page === index + 1 ? "bg-slate-500 text-white" : ""
                  }`}
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
                className="px-3 py-1 text-sm font-normal border hover:bg-slate-600 cursor-pointer hover:text-white"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {showEmailModel && (
        <EmailModel
          setShowEmailModel={setShowEmailModel}
          selectedLeads={selectedLeads}
        />
      )}
      {showAddLead && <AddLeadForm setShowAddLead={setShowAddLead} />}
      {showWhatsAppSend && (
        <WhatsAppCon
          selectedLeads={selectedLeads}
          setSendWhatsApp={setShowWhatsAppSend}
        />
      )}
    </div>
  );
};
