import React from "react";
import { Sidebar } from "../Dashboard/Sidebar";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userID");
  if (!userId) {
    navigate("/login");
  }
  return (
    <>
      <>
        <div>
          <Sidebar />
        </div>
      </>
    </>
  );
};
