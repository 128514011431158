import { Link } from "react-router-dom";
import logo from "../assets/margdarshakendra-logo.webp";
import { FaUser } from "react-icons/fa";

const Navbar = () => {
  return (
    <div className="px-4 m-3">
      <div className="flex items-center justify-between h-16 w-full">
        <div className="cursor-pointer">
          <Link to={"/"}>
            <div className="flex w-48 h-12">
              <img
                src={logo}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </Link>
        </div>
        <div className="flex space-x-8">
          <Link
            to="/login"
            className="flex flex-row px-5 py-3 rounded-md bg-green-700 hover:bg-green-900 transition duration-300 transform hover:scale-105 shadow-md"
          >
            <FaUser className="text-gray-300 mr-3 mt-1" />
            <span className="text-white font-roboto font-medium hover:text-green-200">
              Login
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
