import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ScheduleTask = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [time, setTime] = useState("");
  const [notificationPermissions, setNotificationPermissions] = useState(true);
  // const authUser = useSelector((store) => store.authReducer);
  // const userID = authUser.userID;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve token from localStorage with the correct key
    const token = localStorage.getItem("push-notification-token");

    const task = {
      userID: 5,
      title: title,
      scheduleDateTime: time,
      description: description,
      ...(token && { token }),
    };

    try {
      const response = await fetch("https://margda.in:7000/api/schedule-task", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(task),
      });
      console.log(task);
      const data = await response.json();
      if (response.ok) {
        toast.success("Task Scheduled Successfully!", { autoClose: 3000 });
        resetForm();
      } else {
        toast.error(`Error: ${data.message || "Failed to schedule task"}`, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Network error. Please try again later.", {
        autoClose: 3000,
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // if (file && file.type === 'audio') {
    const reader = new FileReader();

    reader.onload = (e) => {
      localStorage.setItem("customNotificationSound", e.target.result);
    };

    reader.readAsDataURL(file); // Convert the file to a base64-encoded string
    // } else {
    //   alert('Please select a valid MP3 file.');
    // }
  };
  useEffect(() => {
    requestPermission();
  }, []);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      setNotificationPermissions(false);
    }
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setTime("");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-left font-mono">
          Schedule a Task
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          {!notificationPermissions && (
            <div>
              Enable notification in site settings to get notification about
              tasks
            </div>
          )}
          <div>
            <label
              className="block text-gray-700 font-medium mb-2 text-left"
              htmlFor="title"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="shadow-md border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter task title"
            />
          </div>
          <div>
            <label
              className="block text-gray-700 font-medium mb-2 text-left"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="shadow-md border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter task description"
              rows="4"
            />
          </div>
          <div>
            <label
              className="block text-gray-700 font-medium mb-2 text-left"
              htmlFor="time"
            >
              Time (Asian/Kolkata)
            </label>
            <input
              type="datetime-local"
              id="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
              className="shadow-md border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="custom_audio">
              {" "}
              Choose mp3 audio file for custom notification sound
            </label>
            <input
              type="file"
              name="audio"
              id="custom_audio"
              onChange={handleFileChange}
              accept="audio/mp3"
            />
          </div>
          <div></div>
          <button
            type="submit"
            className="w-full bg-gradient-to-br from-gray-800 to-gray-900 text-white font-semibold py-3 rounded-lg shadow-lg transition duration-200 transform hover:scale-105 focus:outline-none font-mono"
          >
            Schedule Task
          </button>
        </form>
      </div>
    </div>
  );
};
