import React, { useState } from "react";

export const AddLeadForm = ({ setShowAddLead }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    userID: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    const userId = localStorage.getItem("userID");
    formData.userID = userId;
    e.preventDefault();
    try {
      const response = await fetch("https://margda.in:7000/api/addlead", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log(result);
      if (response.ok) {
        window.location.reload();
        setMessage("Lead added successfully!");
        setFormData({ name: "", email: "", mobile: "" });
        setShowAddLead(false);
        // navigate('/addlead')
      } else {
        setMessage("Failed to add lead. Try again!");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl -mt-32">
        {" "}
        {/* Increased width */}
        <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">
          Add New Lead
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label
                className="block text-gray-600 text-sm mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter name"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-600 text-sm mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter email"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-600 text-sm mb-2"
                htmlFor="mobile"
              >
                Mobile
              </label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter mobile number"
                required
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowAddLead(false)}
              className="w-1/2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-1/2 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Add Lead
            </button>
          </div>
        </form>
        {message && (
          <div className="mt-4 text-center text-sm text-green-500">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};
