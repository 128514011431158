import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  Polygon,
} from "@react-google-maps/api";
export const AddGeofence = () => {
  const userID = localStorage.getItem("userID");
  const [geofenceName, setGeofenceName] = useState("");
  const [previousGeofences, setPreviousGeofences] = useState([]);

  useEffect(() => {
    fetchGeofences();
  }, []);

  const fetchGeofences = async () => {
    const response = await fetch(
      "https://margda.in:7000/api/android/getGeofences",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      console.log(data.Geofences);
      setPreviousGeofences(data.Geofences);
    }
  };
  const mapContainerStyle = {
    width: "100%",
    height: "400px", // Adjust the height
  };

  const center = {
    lat: 28.59100325186512, // Latitude
    lng: 77.19316005706787, // Longitude
  };
  const [path, setPath] = useState([]);
  const [mapCenter, setMapCenter] = useState(center);
  const [markerPosition, setMarkerPosition] = useState(center);

  const handleMapClick = (event) => {
    const newPath = [
      ...path,
      { lat: event.latLng.lat(), lng: event.latLng.lng() },
    ];
    setPath(newPath);
    setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  const saveGeofence = async () => {
    if (!geofenceName || geofenceName === "") {
      return alert("Enter Geofence Name");
    }
    if (path.length < 3) {
      return alert("Place minumum 3 points");
    }
    console.log(path);
    const response = await fetch(
      "https://margda.in:7000/api/android/add-geofence",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID,
          geofence: path,
          geofenceName,
        }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      alert(data.message);
      const geofence = [
        ...previousGeofences,
        { geofenceName, coordinates: path },
      ];
      setPreviousGeofences(geofence);
    } else if (response.status == "500") {
      alert(data.message);
    } else {
      alert(data.message);
    }
  };

  const clearMap = () => {
    setPath([]);
  };

  const handlePreviousGeofenceChange = async (e) => {
    if (e.target.value == "") {
      return;
    }
    setPath(previousGeofences[e.target.value].coordinates);
    setMapCenter(previousGeofences[e.target.value].coordinates[0]);
    setMarkerPosition(previousGeofences[e.target.value].coordinates[0]);
  };

  const inputRef = useRef(null);

  const handlePlaceSelect = () => {
    const place = inputRef.current.value;
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: place }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        setMapCenter({ lat: location.lat(), lng: location.lng() });
        setMarkerPosition({ lat: location.lat(), lng: location.lng() });
        inputRef.current.value = ""; // Clear the input
      } else {
        alert("Place not found: " + status);
      }
    });
  };

  return (
    <div>
      <div className="bg-gray-800 text-white text-center py-3 mb-4">
        Select a Polygon Path for Geofence
      </div>
      <div className="mb-4">
        <select
          name="geofences"
          id="geofences"
          onChange={handlePreviousGeofenceChange}
        >
          <option value="">Select Previous Geofences</option>
          {previousGeofences.length > 0 &&
            previousGeofences.map((geofence, index) => (
              <option value={index}>{geofence.geofenceName}</option>
            ))}
        </select>
      </div>
      <LoadScript
        googleMapsApiKey="AIzaSyB9df0GL02q2s32tlnqXpv71ZKtOQFOHNU"
        libraries={["places"]}
      >
        <div>
          <input
            type="text"
            ref={inputRef}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search for a place"
            style={{ width: "300px", marginBottom: "10px" }}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            onClick={handlePlaceSelect}
          >
            Search
          </button>
        </div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          // center={center}
          zoom={13} // Adjust zoom level
          onClick={handleMapClick}
        >
          {/* Marker */}
          <Marker position={markerPosition} />
          <Polygon
            path={path}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        </GoogleMap>
      </LoadScript>
      <div className="flex flex-row justify-around mt-10">
        <div className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">
          <button onClick={clearMap}>Cancel</button>
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Geofence Name"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            id="geofenceName"
            value={geofenceName}
            onChange={(e) => setGeofenceName(e.target.value)}
          />
        </div>
        <div
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          onClick={saveGeofence}
        >
          <button>Save Geofence</button>
        </div>
      </div>
    </div>
  );
};
