import { useEffect, useState } from "react";

export const WhatsAppCon = ({ selectedLeads, setSendWhatsApp }) => {
  const [message, setMessage] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [sendWhatsappType, setSendWhatsAppType] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    console.log(selectedLeads);
    fetchWhatsAppProfiles();
  }, []);

  const fetchWhatsAppProfiles = async () => {
    const userID = localStorage.getItem("userID");
    setUserID(userID);
    const response = await fetch(
      "https://margda.in:7000/api/margda/scan-whatsapp/getprofiles",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID }),
      }
    );
    const body = await response.json();
    if (response.ok) {
      console.log(body);
      setProfiles(body.Profiles);
    }
  };

  const handleProfileChange = (e) => {
    if (e.target.value === "") {
      setSelectedProfile(null);
    } else {
      setSelectedProfile(profiles[e.target.value]);
    }
  };

  const sendMessage = async (phone) => {
    const phoneNumbers = selectedLeads.map((lead) => lead.mobile);
    try {
      const response = await fetch(
        "https://margda.in:7000/api/margda/scan-whatsapp/sendmessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            instanceId: selectedProfile.instance,
            phoneNumber: phoneNumbers,
            userID,
            message,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      alert(data.message);
    } catch (e) {
      console.log(e);
      alert("Error in sending message", e);
    }
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl -mt-32">
        <div className="flex flex-col items-center space-y-4 mb-6">
          <label
            htmlFor="message"
            className="block mb-2 text-xl font-medium text-gray-700"
          >
            WhatsApp Message
          </label>
          <select
            name="whatsapp-type"
            className="border border-gray-300 rounded-lg p-2 w-1/2"
            id="whatsapp-type"
            onChange={(e) => {
              setSelectedProfile(null);
              setSendWhatsAppType(e.target.value);
            }}
          >
            <option value="">Select Type</option>
            <option value="scan">Simple Whatsapp</option>
            <option value="cloud">Cloud Whatsapp</option>
          </select>
        </div>
        {sendWhatsappType == "scan" && (
          <div>
            <div>
              <select
                name="profile"
                id="profile"
                onChange={handleProfileChange}
                className="border border-gray-300 rounded-lg p-2 w-1/2 mb-3"
              >
                <option value="">Select Profile</option>
                {profiles.length > 0 &&
                  profiles.map((profile, index) => (
                    <option value={index}>{profile.mobile}</option>
                  ))}
              </select>
            </div>
            <div className="my-3">
              <textarea
                type="text"
                name="message"
                value={message}
                id="message"
                onChange={(e) => setMessage(e.target.value)}
                className="border w-1/2 p-2"
              />
            </div>
            <div>
              {selectedProfile !== null && (
                <button
                  onClick={sendMessage}
                  type="submit"
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                >
                  Send WhatsApp
                </button>
              )}

              <button
                onClick={() => setSendWhatsApp(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
