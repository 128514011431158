import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../Components/Navbar";

export const Home = () => {
  const [ip, setIP] = useState("");

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIP(response.data.ip);
        // console.log(response.data.ip);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIP();
  }, []);
  return <div>{<Navbar />}</div>;
};
