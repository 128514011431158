import React, { useState } from "react";
import { Link } from "react-router-dom";

const MainDashboad = () => {
  const name = localStorage.getItem("name");

  return (
    <div className="flex flex-col justify-start items-start min-h-screen bg-gray-100 p-2">
      <div className="text-lg lg:text-base font-medium text-gray-800">
        Great Day, {name}!
      </div>
      <div className="flex flex-row m-5 gap-10">
        <Link to={"allleads"}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2720/2720810.png"
              alt="Data"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>Data</span>
          </div>
        </Link>

        <Link to={"users"}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
              alt="Data"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>Users</span>
          </div>
        </Link>

        <Link to={""}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/8061/8061995.png"
              alt="Hiring"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>Hiring</span>
          </div>
        </Link>
        <Link to={""}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/8955/8955270.png"
              alt="Work"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>Work</span>
          </div>
        </Link>
        <Link to={""}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2329/2329180.png"
              alt="Counselling"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              Counselling
            </span>
          </div>
        </Link>
        <Link to={""}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/769/769633.png"
              alt="Matri"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>Matri</span>
          </div>
        </Link>
        <Link to={""}>
          <div className="flex flex-col items-center text-black-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2783/2783243.png"
              alt="Marketing"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              Marketing
            </span>
          </div>
        </Link>
        <Link to={""}>
          <div className="flex flex-col items-center text-gray-500 cursor-pointer hover:bg-blue-100 rounded-xl">
            <img
              src="https://cdn-icons-png.flaticon.com/128/602/602175.png"
              alt="Property"
              width={"45px"}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              Property
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MainDashboad;
