import React, { useEffect, useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard, MdOutlineMessage } from "react-icons/md";
import {
  RiSettings4Line,
  RiLogoutBoxLine,
  RiCalendarScheduleLine,
} from "react-icons/ri";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa6";
import { TbReportAnalytics, TbLockPassword } from "react-icons/tb";
import { AiOutlineUser, AiOutlineHeart, AiOutlineGlobal } from "react-icons/ai";
import { FaSms, FaDatabase, FaVideo } from "react-icons/fa";
import { IoIosCall, IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { SiWhatsapp } from "react-icons/si";
import { CgProfile } from "react-icons/cg";
import { Link, Route, Routes, Navigate } from "react-router-dom";
import { AllLeads } from "./AllLeads";
import { Credentials } from "./Credentials";
import SidebarLogo from "../assets/margdarshakendra-logo.webp";
import Admin from "./Admin";
import { BsQrCode } from "react-icons/bs";
import { CompleteProfile } from "../Components/CompleteProfile";
import { ScheduleTask } from "./ScheduleTask";
import { ValidEmails } from "./ValidateEmail";
import { useNavigate } from "react-router-dom";
import { TrackEmployeeMap } from "./TrackEmployeeMap";
import { AddGeofence } from "./AddGeofence";
import { AddWhatsapp } from "./AddWhatsapp";
import MainDashboad from "./MainDashboad";
import AttendenceQrCode from "./AttendenceQrCode";
import { Users } from "./Users";

export const Sidebar = () => {
  const [name, setName] = useState(null);
  const [profileComplete, setProfileComplete] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [profileShow, setProfileShow] = useState(false);
  const [imgDisplay, setImgDisplay] = useState("block");

  useEffect(() => {
    const timer = setTimeout(() => {
      setImgDisplay("none");
    }, 10000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);

  const menus = [
    // { name: "Google Data", link: "googledata", icon: BsDatabaseUp },
    {
      name: "Schedule Task",
      link: "scheduletask",
      icon: RiCalendarScheduleLine,
    },
    {
      name: "validate emails",
      link: "validate-email",
      icon: TbReportAnalytics,
    },
    {
      name: "track-employee",
      link: "track-employee",
      icon: AiOutlineGlobal,
    },
    {
      name: "add-geofence",
      link: "add-geofence",
      icon: AiOutlineGlobal,
    },
    {
      name: "Attendence",
      link: "attendence",
      icon: BsQrCode,
    },
    { name: "Saved", link: "#", icon: AiOutlineHeart },
    { name: "Setting", link: "#", icon: RiSettings4Line },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const userId = localStorage.getItem("userID");
    const name = localStorage.getItem("name");
    setName(name);
    const profile_pic_url = localStorage.getItem("picUrl");
    const fileId = extractFileId(profile_pic_url);
    setProfilePicUrl(`https://drive.google.com/file/d/${fileId}/preview`);
    if (!userId) {
      navigate("/login");
    }
    const checkProfile = localStorage.getItem("picUrl");
    if (!checkProfile || checkProfile == "null" || checkProfile === undefined) {
      setProfileComplete(false);
    } else {
      setProfileComplete(true);
    }
  }, []);

  function extractFileId(url) {
    const parts = url.split("/d/");
    if (parts.length > 1) {
      const fileId = parts[1].split("/")[0];
      return fileId;
    }
    return null; // Return null if the file ID cannot be extracted
  }

  const logoutHandler = () => {
    localStorage.removeItem("userID");
    localStorage.removeItem("picUrl");
    localStorage.removeItem("whatsap");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    navigate("/login");
  };

  const [open, setOpen] = useState(true); // Track sidebar open/closed state

  return (
    <section className="flex gap-6">
      {profileComplete ? (
        <>
          <div
            className={`bg-gradient-to-br from-gray-900 to-gray-800 h-screen fixed overflow-scroll ${
              open ? "w-60" : "w-16"
            } duration-500 text-gray-100 px-4`}
          >
            <div className="py-3 flex justify-between items-center">
              {open && (
                <div className="flex w-full items-center">
                  <img
                    src={SidebarLogo}
                    className="pr-5 py-2 w-full"
                    alt="Sidebar-logo"
                  />
                </div>
              )}
              <HiMenuAlt3
                size={26}
                className="cursor-pointer"
                onClick={() => setOpen(!open)}
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 relative">
              <div
                className="flex flex-row items-center cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md py-2 pl-1"
                onClick={() => setProfileShow(!profileShow)}
              >
                <div
                  style={{
                    position: "relative",
                    width: "50px",
                    height: "50px",
                    overflow: "hidden",
                    borderRadius: "64px",
                  }}
                >
                  <iframe
                    src={profilePicUrl}
                    width="50px"
                    height="50px"
                    sandbox="allow-same-origin allow-scripts allow-presentation"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      border: "0",
                      display: "block",
                      borderRadius: "64px",
                      pointerEvents: "none",
                      zIndex: "1",
                    }}
                  ></iframe>
                  <img
                    src="https://media.istockphoto.com/id/1220827245/vector/anonymous-gender-neutral-face-avatar-incognito-head-silhouette.jpg?s=612x612&w=0&k=20&c=GMdiPt_h8exnrAQnNo7dIKjwZyYqjH4lRQqV8AOx4QU="
                    alt="Cover Image"
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      top: "0",
                      left: "0",
                      width: "50px",
                      height: "50px",
                      borderRadius: "64px",
                      display: imgDisplay,
                      zIndex: "2",
                    }}
                  />
                </div>
                <div className={`text-base ${open ? "ml-4" : "ml-0"}`}>
                  {name ? name : ""}
                </div>
                <div className="flex justify-end" style={{ width: "35%" }}>
                  <IoIosArrowForward
                    className={`text-xl transform duration-300 ${
                      profileShow ? "rotate-90" : "rotate-0"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col items-start ml-[20px] transition-all duration-500 overflow-hidden ${
                  profileShow && open
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <Link to={"profile"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <CgProfile className="mr-4" />
                    Profile
                  </div>
                </Link>
                <Link to={"credentials"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <RiLockPasswordLine className="mr-4" />
                    Credentials
                  </div>
                </Link>
                <Link to={"add-whatsapp"} className="w-full">
                  <div className="flex flex-row items-center p-2 self-start cursor-pointer hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white rounded-md	">
                    <FaWhatsapp className="mr-4" />
                    Scan Whatsapp
                  </div>
                </Link>
              </div>

              {menus.map((menu, i) => (
                <Link
                  to={menu.link}
                  key={i}
                  className={`group flex items-center text-lg gap-3.5 p-3 rounded-xl ${
                    !open
                      ? "justify-center hover:bg-gray-600"
                      : "hover:bg-gradient-to-tr from-blue-600 to-blue-400 text-white"
                  }`}
                >
                  <div className={`${!open ? "ml-3 px-2" : "ml-0"}`}>
                    {React.createElement(menu.icon, { size: "25" })}
                  </div>
                  <h2
                    style={{
                      transitionDelay: `${i + 3}00ms`,
                    }}
                    className={`whitespace-pre duration-500 ${
                      !open && "opacity-0 translate-x-28 overflow-hidden"
                    }`}
                  >
                    {menu.name}
                  </h2>
                  <h2
                    className={`${
                      open && "hidden"
                    } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}
                  >
                    {menu.name}
                  </h2>
                </Link>
              ))}
            </div>
          </div>

          <div
            className={`${
              open ? "ml-64" : "ml-16"
            } duration-500 m-3 text-xl text-gray-900 font-semibold w-full`}
          >
            <div className="flex flex-row justify-between items-stretch	p-3 pt-2">
              <div className="flex flex-row items-center">
                <Link to={""}>
                  <div className="flex flex-row text-lg lg:text-base font-medium text-gray-500 ml-3 cursor-pointer items-center hover:text-gray-700">
                    <div className="mr-1">
                      <MdOutlineDashboard />
                    </div>
                    Dashboard
                  </div>
                </Link>
                <div className="mx-5 ml-[60px] flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <FaDatabase />
                  </div>

                  <div className="font-light text-base">50</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <FaSms />
                  </div>
                  <div className="font-light text-base">4</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <IoIosCall />
                  </div>
                  <div className="font-light text-base">0</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <MdOutlineMessage />
                  </div>
                  <div className="font-light text-base">0</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <SiWhatsapp />
                  </div>
                  <div className="font-light text-base">4</div>
                </div>
                <div className="mx-5 flex flex-row text-blue-500 items-center cursor-pointer hover:text-blue-400 ">
                  <div className="mr-2">
                    <FaVideo />
                  </div>
                  <div className="font-light">0</div>
                </div>
              </div>
              <div>
                <div
                  className="flex flex-row text-lg lg:text-base font-medium text-gray-500 ml-3 cursor-pointer items-center hover:text-gray-700"
                  onClick={logoutHandler}
                >
                  <RiLogoutBoxLine />
                  Logout
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "rgb(184 197 225)",
                width: "100%",
                height: "1px",
              }}
            ></div>
            <Routes>
              <Route path="/" element={<MainDashboad />} />
              {/* <Route path="admin" element={<Navigate to="admin" />} /> */}
              <Route path="allleads" element={<AllLeads />} />
              <Route path="users" element={<Users />} />
              <Route path="credentials" element={<Credentials />} />
              {/* <Route path="admin" element={<Admin />} /> */}
              {/* <Route path="googledata" element={<GoogleData />} /> */}
              <Route path="scheduletask" element={<ScheduleTask />} />
              <Route path="validate-email" element={<ValidEmails />} />
              <Route path="track-employee" element={<TrackEmployeeMap />} />
              <Route path="add-geofence" element={<AddGeofence />} />
              <Route path="add-whatsapp" element={<AddWhatsapp />} />
              <Route path="attendence" element={<AttendenceQrCode />} />
              <Route path="profile" element={<CompleteProfile />} />
            </Routes>
          </div>
        </>
      ) : (
        <>
          <CompleteProfile />
        </>
      )}
    </section>
  );
};
