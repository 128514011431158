export const ValidEmails = () => {
  return (
    <div className="rounded-lg shadow-lg p-4">
      <table className="min-w-full table-auto border-collapse">
        <thead>
          <tr className="bg-gray-800 text-white text-center">
            <th className="py-3 px-4 uppercase font-semibold text-sm">Email</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm">
              Serial No.
            </th>
            <th className="py-3 px-4 uppercase font-semibold text-sm">Name</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm">
              Mobile
            </th>
            <th className="py-3 px-4 uppercase font-semibold text-sm">
              Bad Mail
            </th>
            <th className="py-3 px-4 uppercase font-semibold text-sm">
              Email Sent
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};
