import React, { useState } from "react";
import { FaPhone, FaStar } from "react-icons/fa";
import CsvDownloader from "react-csv-downloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const GooglePlaceGetData = () => {
  const [keyword, setKeyword] = useState("");
  const [place, setPlace] = useState("");
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState({});
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://certc.in:4000/api/getdata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword: `${keyword} in ${place}`,
          limit: limit,
        }),
      });

      const result = await response.json();
      setData(result.data);

      toast.success(
        `Data fetched for "${keyword} in ${place}". Showing ${result.data.length} results.`,
        {
          className: " text-white",
        }
      );
      setKeyword("");
      setPlace("");
      setLimit(5);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleShowMore = (index) => {
    setShowMore((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const csvData = data.map((hotel) => ({
    name: hotel.name ? hotel.name.replace(/,/g, "") : "No name",
    address: hotel.address ? hotel.address.replace(/,/g, "") : "No address",
    phone: hotel.phone !== "N/A" ? hotel.phone.replace(/,/g, "") : "No phone",
    rating: hotel.rating ? hotel.rating : "No rating",
    reviewsCount: Math.abs(
      hotel.reviews
        ? parseInt(hotel.reviews.replace(/,/g, "").replace(/[()]/g, ""))
        : 0
    ),
    type: hotel.type ? hotel.type : "No Types",
  }));

  const csvHeaders = [
    { id: "name", displayName: "Name" },
    { id: "address", displayName: "Address" },
    { id: "phone", displayName: "Phone" },
    { id: "rating", displayName: "Rating" },
    { id: "reviewsCount", displayName: "Reviews Count" },
    { id: "type", displayName: "Type" },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col lg:flex-row items-start p-4 lg:p-8 space-y-8 lg:space-y-0 lg:space-x-8">
      {/* Toast Container */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* Left Form */}
      <div className="w-full lg:w-1/3 p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold mb-6">Search Your Business Data</h1>
        <div className="mb-8">
          <label className="block text-gray-700 mb-2 font-semibold">
            Keyword
          </label>
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="e.g. hotels, school, Shopping mall, clinic, bank ..."
          />
        </div>
        <div className="mb-8">
          <label className="block text-gray-700 mb-2 font-semibold">
            Place
          </label>
          <input
            type="text"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="e.g. Please Enter your desired location"
          />
        </div>
        <div className="mb-8">
          <label className="block text-gray-700 mb-2 font-semibold">
            Limit
          </label>
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Limit of results"
          />
        </div>
        <button
          onClick={fetchData}
          className="w-full p-3 bg-blue-500 text-white rounded-md"
          disabled={loading}
        >
          {loading ? "Loading..." : "Search"}
        </button>
      </div>

      {/* Right Data Display */}
      <div className="w-full lg:w-2/3 h-full">
        {data.length > 0 ? (
          <div>
            <div className="overflow-x-auto overflow-y-auto h-[70vh] bg-white shadow-lg rounded-lg p-4">
              <table className="table-auto w-full">
                <thead>
                  <tr className="bg-gray-300 text-left">
                    <th className="p-3">Name</th>
                    <th className="p-3">Address</th>
                    <th className="p-3">Phone</th>
                    <th className="p-3">Rating</th>
                    <th className="p-3">Review</th>
                    <th className="p-3">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((el, index) => (
                    <tr key={index} className="border-t-2">
                      <td className="p-3 text-left max-w-40">
                        {el.name.split(" ").slice(0, 4).join(" ")}
                        {el.name.split(" ").length > 3 && "..."}
                      </td>
                      {/* <td className="p-3 max-w-xs text-left">{el.address}</td> */}
                      <td className="p-3 max-w-xs text-left">
                        {showMore[index] || el.address.length <= 20 ? (
                          <span>{el.address}</span>
                        ) : (
                          <span>{el.address.slice(0, 30)}...</span>
                        )}
                        {el.address.length > 50 && (
                          <button
                            onClick={() => toggleShowMore(index)}
                            className="text-blue-500 ml-2"
                          >
                            {showMore[index] ? "View Less" : "View More"}
                          </button>
                        )}
                      </td>
                      <td className="p-3 flex items-center text-left mt-5 w-40">
                        <FaPhone className="mr-2 text-green-500" />
                        {el.phone !== "N/A" ? el.phone : "No phone"}
                      </td>
                      <td className="p-3 flex-col-reverse">
                        <div className="flex">
                          <div className="mr-2 font-semibold">
                            {Number(el.rating).toFixed(1)}
                          </div>

                          {[...Array(Math.round(el.rating))].map((_, i) => (
                            <FaStar key={i} className="text-green-500 mt-1" />
                          ))}
                        </div>
                      </td>
                      <td className="p-3 text-left">{el.reviews} Reviews</td>
                      <td className="p-3 text-left">{el.type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* CSV Download Button */}
            <div className="mt-4 w-full flex justify-end">
              <CsvDownloader
                datas={csvData}
                filename="hotel_data"
                columns={csvHeaders}
              >
                <button className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded">
                  Download CSV
                </button>
              </CsvDownloader>
            </div>
          </div>
        ) : (
          <div className="p-4 bg-red-100 rounded">
            <p>No data found. Please enter a valid keyword and search again.</p>
          </div>
        )}
      </div>
    </div>
  );
};
