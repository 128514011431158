import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../Pages/Home";
import { Login } from "../Pages/Login";
import { GooglePlace } from "../Pages/GooglePlace";
import { GooglePlaceGetData } from "../Pages/GooglePlaceGetData";
import OtpDialog from "../Pages/Otpdialog";
import { Sign } from "../Pages/Sign";
import { Dashboard } from "../Pages/Dashboard";
export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign" element={<Sign />} />
      <Route path="/dailog" element={<OtpDialog />} />
      <Route path="/googleplacedata" element={<GooglePlace />} />
      <Route path="/googleplacegetdata" element={<GooglePlaceGetData />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      {/* <Route path="/addlead" element={<AddLeadForm />} /> */}
    </Routes>
  );
};
