import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";

const AttendenceQrCode = () => {
  const [qrData, setQrData] = useState("");
  const [attendenceData, setAttendenceData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    fetchAttendenceData();
  }, []);

  const fetchAttendenceData = async () => {
    const response = await axios.get(
      "https://margda.in:7000/api/android/getattendencedata"
    );
    if (response.status === 200) {
      const data = response.data.AttendenceData;
      const mergedData = Object.values(
        data.reduce((acc, item) => {
          if (!acc[item.userID]) {
            acc[item.userID] = { ...item };
          } else {
          }
          return acc;
        }, {})
      );

      const addName = await Promise.all(
        mergedData.map(async (item) => {
          const name = await fetchNames(item.userID);
          item.Name = name;
          return item;
        })
      );
      console.log(data);
      console.log(addName);
      setMergedData(addName);
      setAttendenceData(data);
    }
  };

  useEffect(() => {
    const generateQrData = () => {
      const expirationTime = Math.floor(Date.now() / 1000) + 60;
      return `https://margda.in:7000/api/android/attendence?expires=${expirationTime}`;
    };

    setQrData(generateQrData());

    const interval = setInterval(() => {
      setQrData(generateQrData());
    }, 60000); // 1 minute

    return () => clearInterval(interval);
  }, []);

  const convertToIST = (utcDate) => {
    const utcDateObj = new Date(utcDate);
    return utcDateObj.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
  };

  const handleEmployeeChange = (e) => {
    if (e.target.value === "") {
      return;
    }
    const filter = attendenceData.filter(
      (item) => item.userID === e.target.value
    );
    const addDateTime = filter.map((item) => {
      const eventDateTime = convertToIST(item.edate);
      item.eventDateTime = eventDateTime;
      return item;
    });
    setSelectedEmployee(addDateTime);
  };

  const fetchNames = async (userID) => {
    try {
      const response = await axios.post(
        "https://margda.in:7000/api/android/getuserdata",
        { userID }
      );
      if (response.status === 200) {
        return response.data.User[0].name ? response.data.User[0].name : "N/A";
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          console.log(error.response);
          return "N/A";
        }
      }
    }
  };

  const fetchaddress = async (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB9df0GL02q2s32tlnqXpv71ZKtOQFOHNU`;
    const response = await fetch(url);
    const data = await response.json();
    if (response.ok) {
      return data.results[0].formatted_address;
    } else {
      return "N/A";
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h1>Scan the QR Code</h1>
      <QRCodeCanvas value={qrData} size={256} />
      <div className="m-4">
        <select
          name="Employees"
          id="Employees"
          onChange={handleEmployeeChange}
          className="p-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select Employee You Want to see Attendence</option>
          {mergedData.length > 0 &&
            mergedData.map((employee, index) => (
              <option value={employee.userID}>{employee.Name}</option>
            ))}
        </select>
      </div>
      {selectedEmployee && selectedEmployee.length > 0 && (
        <div className="flex justify-center">
          <table className="border-collapse border border-gray-900 w-[90%]">
            <thead>
              <tr>
                <th
                  key={0}
                  className="border border-gray-300 p-2 bg-gray-200 text-base font-serif"
                >
                  {"Event"}
                </th>
                <th
                  key={1}
                  className="border border-gray-300 p-2 bg-gray-200 text-base font-serif"
                >
                  {"Time"}
                </th>
                <th
                  key={2}
                  className="border border-gray-300 p-2 bg-gray-200 text-base font-serif"
                >
                  {"Location"}
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedEmployee.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    key={0}
                    className="border border-gray-300 p-2 text-center text-base font-serif"
                  >
                    {row.event}
                  </td>
                  <td
                    key={1}
                    className="border border-gray-300 p-2 text-center text-base font-serif"
                  >
                    {row.eventDateTime}
                  </td>
                  <td
                    key={2}
                    className="border border-gray-300 p-2 text-center text-base font-serif"
                  >
                    {row.address || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AttendenceQrCode;
