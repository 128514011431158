import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import axios from "axios";

export const Credentials = () => {
  const [userID, setUserID] = useState();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const userID = localStorage.getItem("userID");
    setUserID(userID);
    try {
      const response = await axios.post(
        "https://margda.in:7000/api/getuserdata",
        { userID }
      );
      if (response.status === 200) {
        console.log(response.data.User);
        setLogin(response.data.User[0].login);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const updateCredentials = async () => {
    try {
      const response = await axios.put(
        "https://margda.in:7000/api/updatecredentials",
        { userID, loginID: login, password: password }
      );
      if (response.status === 200) {
        alert("credentials updated successfully");
      }
    } catch (e) {
      if (e.response.data.Error.code == 23505) {
        alert("login id alreay exists");
      } else {
        console.log(e.response);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-700">
          Update Credentials
        </h2>
        <div className="mb-4">
          <label
            htmlFor="login"
            className="block text-gray-600 font-medium mb-2"
          >
            Login
          </label>
          <input
            type="text"
            id="login"
            placeholder="Enter your login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block text-gray-600 font-medium mb-2"
          >
            New Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-gray-500"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <button
          onClick={updateCredentials}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300"
        >
          Update Credentials
        </button>
      </div>
    </div>
  );
};
